// Override Bootstrap's Sass default variables
//
// Nearly all variables in Bootstrap are written with the `!default` flag.
// This allows you to override the default values of those variables before
// you import Bootstrap's source Sass files.
//
// Overriding the default variable values is the best way to customize your
// CSS without writing _new_ styles. For example, you can either change
// `$body-color` or write more CSS that override's Bootstrap's CSS like so:
// `body { color: red; }`.

// Include functions first
@import "bootstrap/scss/functions";

// Import the default vars, to make it easier
@import "bootstrap/scss/variables";


// Toggle global options
$enable-rounded: false;

// The text-container bg is roughly: #0D1B25;


$primary: #6fa8b5;
$secondary: #0e1622;
$success: $green !default;
$info: hsl(185, 12%, 64%);
$warning: $yellow !default;
$danger: $red !default;
$light: hsl(0, 0%, 85%);
$dark: hsl(0, 0%, 5%);


$theme-colors: (
        primary: $primary,
        secondary: $secondary,
        success: $success,
        info: $info,
        warning: $warning,
        danger: $danger,
        light: $light,
        dark: $dark,
        neutral: #90a0af,
);

$theme-colors: map-merge((
        "infoalpha": rgba($info, 0.8),
        "darkalpha": rgba($dark, 0.8),
), $theme-colors);

// Fonts
$font-family-base: 'Open Sans', sans-serif;

//Links
$link-color: #5c92bd;

// Body - used eg when iOS allow you to scroll beyond the border
//$body-color: $gray-900 !default;
//$body-bg: #90a0af;
// ^ Warning! This seems to alter the form background also, so was removed...


// ?? Whether to import this again after setting the defaults?
@import "bootstrap/scss/variables";