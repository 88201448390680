@import "peter_functions";

// Seems the following 3 needed for
// @includes (media-breakpoint-up, img-fluid)
// to work.
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

//Ensure a min height for the content
html, body {
  //position: relative;
  height: 100%;
  min-height: 100%;
}

.letterbox-image-holder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  //background-color: map-get($theme-colors, info);
  //an element using this class should likely set a max-height,
  // or alternatively a fixed height, to avoid shrinkage

  img {
    @include img-fluid();
    height: inherit;
    max-height: 100%;
  }

  .bg-image-holder {
    flex-grow: 1;
    // Add your: background-image: url("/static/images/...");
    background-size: contain;
    align-self: stretch;
    background-position: center;
    background-repeat: no-repeat;
  }
}

//Ported across:

//Give a default image to the entrancebody
.entrancebody {
  //background-image: url(static_asset('images/backgrounds/background-1.jpg')); //For the new css location relative
  //The background-image will be set inline
  //url('./images/backgrounds/background-1.jpg');

  background-position: 50% 0px;
  background-attachment: fixed;
  background-size: cover;

  &.errorbody {
    background-image: url(static_asset('images/Port_and_lighthouse_overnight_storm_with_lightning_in_Port-la-Nouvelle.jpg'));
    //background-image: radial-gradient(circle farthest-corner at 50% 0%, hsla(0, 0%, 100%, .75), hsla(0, 0%, 100%, 0)), url(static_asset('images/Port_and_lighthouse_overnight_storm_with_lightning_in_Port-la-Nouvelle.jpg'));
  }
}


.homebody {
  background-image: url(static_asset('images/Karpatskyy_05-(Custom).jpg'));
  background-position: 50% 0px;
  background-size: cover;
  //background-repeat: repeat-y;
  background-attachment: fixed;
}

//Give an overlay screen that will mask (darken) the image.
//Therefore put default white text over the top
.entrancescreen {
  height: 100%;
  overflow: auto;

  //Just do the sides here, to avoid h-100 + overflow auto issues.
  padding-right: 2%;
  padding-left: 2%;

  background-color: rgba(0, 0, 0, .5);
  color: white;

  text-align: center;
  @include media-breakpoint-up(md) {
    text-align: initial;
  }
}


.container-vertical {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
}

.container-horizontal {
  display: flex;
  justify-content: space-between;

  @include media-breakpoint-up(md) {
    flex-direction: row;
    align-items: flex-start;
    //Don't do anything fancy on large screens originally,
    //though class .align-items-end may be used in conjunction

    &.ch-end-align {
      align-items: flex-end;
    }
  }

  flex-direction: column;
  align-items: center;
}

//Font size useages
.entranceheading {
  font-weight: 600;
}

.timedatetime {
  @include media-breakpoint-up(md) {
    font-size: 4.5rem; //eg 72px at rem=16;
  }
  font-size: 2.5rem;
}

.timedatedate {
  @include media-breakpoint-up(md) {
    font-size: 3rem; //eg 48px at rem=16;
  }
  font-size: 1.5rem;
}


//Enterbutton
.enterbutton {
  font-size: 16px;

  text-transform: uppercase;
  letter-spacing: 0.25em;

  //border-radius: ($spacer * 0.25);

  margin-right: ($spacer * 1.5); //x1.5 equiv to m-4
  margin-left: ($spacer * 1.5); //x1.5 equiv to m-4
  padding: 1em 2em;

  margin-top: ($spacer * 2); //x1.5 equiv to m-4
  margin-bottom: ($spacer * 2); //x1.5 equiv to m-4
  @include media-breakpoint-up(md) {
    margin-top: initial;
    margin-bottom: initial;
  }
}


//Main page
.about-me-header {
  text-transform: uppercase;
  font-size: 1.25rem;
  letter-spacing: 0.5em;
  //color: #35353d;

  @include media-breakpoint-down(sm) {
    letter-spacing: 0.25em; //Was 0.5, but the text started to overflow, so used the media breakpoint
  }
}

.about-me-section {
  color: #888;
}

.text-container {
  background-color: rgba(13, 27, 37, .85);
  color: #bfceda;

  padding: ($spacer * 1);

  h1, h2, h3, h4, h5, h6 {
    text-align: center;
    color: #eee;
  }
}

// Form text is slightly too dark by default
.form-text {
  color: #888;
}

.text-entry + .text-entry {
  margin-top: ($spacer * 2);
}


//Vue elements hidden when cloaked (before rendering)
[v-cloak] {
  display: none
}
